@import "variables";
@import "mixins";

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
hr {
  margin: 0;
}

button.btn.outline {
  @extend %button;
  color: $primary;
  background: $primary-10;
  padding: 20px 28px;
  border-radius: 8px;

  &:hover {
    background: $hover-button;
    color: $white;
  }

  &:active {
    background: $active-button;
    color: $white;
  }

  outline: none;
  box-shadow: none;
}

button.btn.primary {
  @extend %button;
  height: 56px;
  color: $white;
  background: $primary;
  padding: 20px 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  box-shadow: none;

  &:hover {
    background: $hover-button;
    color: $white;
  }

  &:active {
    background: $active-button;
    color: $white;
  }

  .badge {
    margin-left: 16px;
    display: flex;
    height: 24px;
    padding: 0 9.5px;
    align-items: center;
    justify-content: center;
    position: static;
  }

  .bg-yellow {
    background: $yellow;
    color: $dark;
    @extend %subtitle2;
  }
}

a.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    margin-right: 10px;
    color: $primary;
  }

  span {
    @extend %subtitle1;
    color: $primary;
    border-bottom: 3px solid $primary-30;
    white-space: nowrap;
  }

  &-small {
    span {
      @extend %body2;
    }
  }

  &-medium {
    span {
      @extend %body1;
    }
  }

  &:hover {
    i {
      color: $secondary-variant;
    }
    span {
      color: $secondary-variant;
      border-bottom-color: $secondary-variant-30;
    }
  }

  &:active {
    span {
      color: $active-link;
      border-bottom-color: $active-link-border;
    }
    i {
      color: $active-link;
    }
  }
}

a.footer-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  i {
    color: $secondary-light;
    margin-right: 10px;
  }

  span {
    @extend %subtitle1;
    color: $secondary-light;
    border-bottom: 3px solid rgba($color: $secondary-light, $alpha: 0.3);
    white-space: nowrap;
  }

  &-small {
    span {
      @extend %body2;
    }
  }

  &-medium {
    span {
      @extend %body1;
    }
  }

  &:hover {
    span {
      color: $hover-footer-link;
      border-bottom-color: $hover-footer-link-border;
    }
    i {
      color: $hover-footer-link;
    }
  }

  &:active {
    span {
      color: $active-footer-link;
      border-bottom-color: $active-footer-link-border;
    }
    i {
      color: $active-footer-link;
    }
  }
}

.bg-gray {
  background-color: $background;
}

.modal-backdrop {
  background: rgba($secondary-variant, 0.3);
  backdrop-filter: blur(4px);
  opacity: 1 !important;
}

.modal-dialog {
  max-width: 532px;
  .modal-content {
    border-radius: 16px;
    border: none;

    .cross-btn {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }
  }
}

.modal-warper {
  .header {
    @extend %header2;
    color: $dark;
  }
}

.form {
  background: $secondary-light-30;
  border-radius: 8px;

  input,
  textarea {
    width: 100%;
    padding: 14px 0 14px 14px;
    height: 56px;
    border-radius: 8px;
    border: 2px solid $input-default;

    &.ng-invalid.ng-touched {
      border: 2px solid $error;
      background: $error-10;
    }

    &:hover {
      background: $secondary-light-10;
    }

    &:active,
    &:focus {
      background: $secondary-light-10;
      border: 2px solid $secondary-variant;
      outline: 0 none;
      box-shadow: none;
    }

    &[type="checkbox"] {
      position: relative;
      padding: 0;
      border: 2.5px solid $primary !important;
      border-radius: 3px;
      transform: scale(0.75);
      width: 24px;
      height: 24px;
      background-color: unset !important;
      cursor: pointer;

      &:checked {
        background-color: $primary !important;
        border: none !important;
      }

      &:checked::after {
        border-bottom: 2.5px solid white;
        border-right: 2.5px solid white;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(1.2) translate(0.5px, -1px) translate(-50%, -50%)
          rotate(45deg);
        border: none;
        display: block;
        height: 12px;
        width: 6px;
      }

      &::before {
        content: "";
        display: block;
        height: 40px;
        width: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transition: opacity 0.1s, transform 0.1s;
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
        background-color: $primary-10;
      }

      &:hover::before {
        transform: translate(-50%, -50%) scale(1.3);
        opacity: 1;
      }

      &:disabled {
        border-color: #c4c4c4 !important;
        &:checked {
          background-color: #c4c4c4 !important;
        }
        &::before {
          background-color: transparent !important;
        }
      }

      &:active,
      &:focus,
      &:hover {
        background-color: unset;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }

  textarea {
    height: 112px;
    resize: none;
  }

  .field {
    padding-bottom: 16px;
    label,
    input,
    textarea {
      @extend %body1;
    }

    label {
      margin-bottom: 4px;
      &.error {
        color: $primary;
      }

      &.link {
        @extend .link;
        a {
          @extend %body1;
        }
      }
    }
  }

  .primary {
    min-width: 180px;
  }
  .error-text {
    @extend %body2;
    padding-top: 4px;
    color: $error;
  }
  button.disabled {
    cursor: default;
    pointer-events: none;
  }
  .button-wrapper {
    & > span:first-child {
      width: 24px;
      height: 24px;
      border: 3px solid rgba(white, 0.5);
      border-right-color: #ffffff;
    }
  }
  .spinner-wrapper {
    & > span:first-child {
      width: 24px;
      height: 24px;
      border: 3px solid #457b9d;
      border-right-color: rgba(#457b9d, 0.3);
    }
  }

  .unknown-error {
    @extend %body2;
    color: $error;
  }
}

.submit-success {
  background: $secondary-light-30;
  width: 100%;
  text-align: center;
  border-radius: 8px;

  img {
    margin: 65px 0;
  }
  .title {
    @extend %header3;
    margin-bottom: 15px;
  }
  .subtitle {
    @extend %body1;
    text-align: center;
  }
}

.breadcrumb-item {
  @extend %body1;
  &:last-child {
    &::after {
      float: right;
      padding-left: $breadcrumb-item-padding-x;
      color: $breadcrumb-divider-color;
      content: $breadcrumb-divider;
    }
  }
  a {
    display: inline-block;
    span {
      padding-bottom: 2px;
    }
  }
}

.color-sample-container {
  width: 50px;
  height: 25px;
  margin: 0 10px;
  border-radius: 10px;
}

.accordion-item {
  margin-bottom: 24px;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border: 0 none;
  .card-header {
    padding: 0;
    background: $white;
    border-bottom: 0 none;
  }
  .card-body {
    padding: 0 15px 30px;
  }
}

.custom-container,
.custom-landing-container {
  padding-left: 16px;
  padding-right: 16px;
}

@include media-breakpoint-down(sm) {
  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
    .modal-content {
      border-radius: 0;
      height: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .custom-container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .custom-landing-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1512px) {
  .custom-container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1512px) {
  .custom-container {
    margin: unset;
    padding-left: 88px;
    padding-right: 88px;
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .custom-landing-container {
    margin: unset;
    padding-left: 88px;
    padding-right: 88px;
    width: 100%;
  }
}
