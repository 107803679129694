$primary: #e42535;
$primary-30: rgba(228, 37, 53, 0.3);
$primary-10: rgba(228, 37, 53, 0.1);

$secondary: #1d3557;
$secondary-variant: #457b9d;
$secondary-variant-30: rgba(69, 123, 157, 0.3);
$secondary-light: #a8dadc;
$secondary-light-30: #e5f4f5;
$secondary-light-30-success: #d3e2e3;
$secondary-light-30-variant: #47667f;
$secondary-light-10: #f6fbfb;

$background: #eaefe5;
$gray: #c4c4c4;

$white: #ffffff;
$white-50: rgba(255, 255, 255, 0.5);
$dark: #201b18;
$error: #ec0000;
$error-10: #fde6e6;
$medium: #f37400;
$high: #08ad59;
$low: #e42535;

$active-link: #1a81c2;
$active-link-border: rgba(26, 129, 194, 0.3);

$hover-footer-link: #e3f0d6;
$hover-footer-link-border: rgba(227, 240, 214, 0.3);

$active-footer-link: #c9e3ae;
$active-footer-link-border: rgba(201, 227, 174, 0.3);

$yellow: #ffc700;
$hover-button: #e4331b;
$active-button: #cd2e19;

$input-default: #ced4da;

$iris: #5c4acb;
$sea-green: #43ae5a;
$blue: #2099dd;

$dark-gray: #8f8d8c;

%header1 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
}

%header2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

%header3 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
}

%subtitle1 {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}

%subtitle2 {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

%subtitle3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

%subtitle4 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
}

%body1 {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}

%body1-italic {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}

%body2 {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

%body3 {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

%button {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-align: left;
}
